// TestimonialsSlider.js - Slider avec testimonials
import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TestimonialsSlider = () => {
    const testimonials = [
        {
          text: "J’ai une assez bonne idée de ce que j’aime et de l’esthétique que je cherche, je n’ai jamais validé une direction artistique aussi rapidement ! Thibaut a fait preuve d’une qualité d’écoute inégalable.",
          author: "David Amar, Nous sommes le futur"
        },
        {
          text: "Thibaut a réussi à créer une identité graphique forte et originale, qui correspond à nos attentes et aux valeurs que nous souhaitons mettre en avant. Sur la communication, il a su prendre le temps de nous écouter, et d'échanger plusieurs fois jusqu'à trouver LA solution.",
          author: "Pauline Domercq, Neftys"
        },
        {
          text: "Ce sont ses compétences en design et en stratégie de marque qui ont fait la différence. Nous nous sommes très vite compris sur le destination de notre site web. Ce qui a permis de réaliser le projet en quelque jour, sans mauvaise surprise. Clarté, efficacité, disponibilité.",
          author: "Jean-Baptiste Cousin, Smash Group"
        },
        {
          text: "Créativité + Réactivité + Reco au Top = Formule Magique. Je recommande sans hésitation le travail de Thibaut. Moi dans tous les cas, je ferai à nouveau appel à ses services ",
          author: "Stéphane Trupheme, Captain Marketing"
        },
        {
          text: "Thibaut comprend rapidement les enjeux et besoins que j’ai pu avoir pour répondre de manière personnalisé à la création de ma MVB. La méthodologie est très efficace et permet de ce concentrer sur l’essentiel pour pouvoir déployer sa marque rapidement. C’est véritablement une co-création puisque Thibaut t’embarque et réalise les modifications directement avec toi. Un vrai plaisir !",
          author: "Vincent Magnier, Gwailo"
        },
      ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <Box id="reviews" sx={{ my: 4, textAlign: 'center', minHeight:'100vh', display:'grid', px:2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
        <IconButton onClick={handlePrev} sx={{ position: 'absolute', left: 0 }}>
          <ArrowBackIosIcon />
        </IconButton>
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0}}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h4" component="p" sx={{ mb: 2, width:{ xs: '80%', md: '60%' }, margin: '0 auto', fontSize:{ xs: '1.6em', md: '2em' } }}>
            "{testimonials[currentIndex].text}"
          </Typography>
          <Typography variant="body1" component="p" sx={{ mt:4 }}>
            {testimonials[currentIndex].author}
          </Typography>
        </motion.div>
        <IconButton onClick={handleNext} sx={{ position: 'absolute', right: 0 }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TestimonialsSlider;