/* Configuration du thème pour le site */
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#395BCC', // Bleu
    },
    secondary: {
      main: '#000000', // Black color for contrasting elements
    },
    text: {
      primary: '#000000', // Black color for text
    },
    custom: {
      blue: '#395BCC',
      yellow: '#F9D489',
      pink: '#F5BDBC',
      beige: '#FDFAF5',
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    h1: {
      fontFamily: 'Ferom',
      fontWeight: 500,
      fontSize: '6rem',
      lineHeight:'1em',
    },
    h2: {
      fontFamily: 'Ferom',
      fontWeight: 500,
      fontSize: '4rem',
    },
    h3: {
      fontFamily: 'Ferom',
      fontWeight: 500,
    },
    h4: {
      fontFamily: 'Ferom',
    },
    h5: {
      fontFamily: 'Ferom',
    },
    h6: {
      fontFamily: 'Ferom',
    },
    button: {
      fontWeight: 500,
      textTransform: 'none',
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        contained: {
          borderRadius: '50px',
          padding: '10px 20px',
          boxShadow: 'none',
          backgroundColor: '#000000', // Black color for contained buttons
          color: '#FFFFFF', // White text color for buttons
          '&:hover': {
            backgroundColor: '#2F2F2F', // Slightly lighter black on hover
          },
        },
        outlined: {
          borderRadius: '50px',
          padding: '10px 20px',
          boxShadow: 'none',
          border: '1px solid #000000', // Black border for outlined buttons
          color: '#000000', // Black text color for outlined buttons
          '&:hover': {
            borderColor: '#2F2F2F', // Slightly lighter border on hover
            color: '#2F2F2F', // Slightly lighter text on hover
          },
        },
      },
    },
  },
});

export default theme;