// WorkSection.js - Présentation des travaux
import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const works = [
  {
    title: "Papa Outang",
    image: "/static/images/po.jpg",
    description: "Papa Outang est une marque engagée fondée en 2019, dont la mission est de lutter contre la déforestation en Indonésie. Chaque produit vendu contribue à sauver des mètres carrés de forêt tropicale en partenariat avec l'association Kalaweit, qui protège la réserve Dulan, une poche précieuse de biodiversité menacée par les industries de l'huile de palme et du charbon. Cette réserve abrite une centaine d'orangs-outans et d'autres espèces en danger.",
    details: "En tant que cofondateur, j’ai supervisé toutes les étapes de la création de Papa Outang : conception de l'identité visuelle, design des packagings, et stratégie de communication. Mon objectif était de fusionner esthétique et éthique, créant ainsi une marque qui résonne auprès des citoyens soucieux de l'environnement. Aujourd'hui, la marque est distribuée dans des enseignes comme Monoprix.",
    gallery: [
      "/static/images/po_1.gif",
      "/static/images/po_A.jpg",
      "/static/images/po_2.jpg",
      "/static/images/po_3.jpg",
      "/static/images/po_4.jpg",
      "/static/images/po_5.jpg",
      "/static/images/po_6.jpg",

    ],
    skills: [
      "Identité visuelle",
      "Stratégie de marque",
      "Packaging",
      "Stratégie de contenu",
      "Storytelling",
      "Direction artistique",
      "Création de contenus"
    ]  },
    {
      title: "Sophie Frantz",
      image: "/static/images/sophie.jpg",
      description: "Sophie Frantz est une consultante spécialisée dans l'intelligence collective. Elle accompagne les organisations et les dirigeants dans la mise en place de stratégies collaboratives qui favorisent l'innovation et la transformation.",
      details: "Mon rôle a été de repenser l'identité visuelle de Sophie Frantz à travers le rebranding de ses supports, notamment les maquettes de son site web et les vignettes de son podcast. Cette transformation visuelle a permis d’aligner son image sur ses principes d’intelligence collective, avec un design moderne et cohérent.",
      gallery: [
        "/static/images/sophie_1.jpg",
        "/static/images/sophie_A.jpg",
        "/static/images/sophie_2.jpg",
        "/static/images/sophie_3.jpg"
      ],
      skills: ["Stratégie de marque", "Identité visuelle", "Design site web", "Design de podcast", "Direction artistique"]
    },    
    {
      title: "Pototam",
      image: "/static/images/pototam.jpg",
      description: "Pototam est une marque française de soins biologiques et naturels dédiée aux bébés, enfants, et mamans. Fondée en 2019, Pototam se distingue par son engagement à offrir des produits sains et sans additifs, avec une démarche profondement écoresponsable.",
      details: "En tant que freelance, j’ai réalisé tous les packagings pour Pototam et contribué à leur identité visuelle. J’ai également conçu plusieurs supports imprimés, comme des affiches et brochures, et travaillé sur des pages spécifiques du site web. Mon travail a permis d'assurer une cohérence graphique sur l’ensemble des supports tout en reflétant l’essence de la marque.",
      gallery: [
        "/static/images/pototam_1.jpg",
        "/static/images/pototam_2.jpg",
        "/static/images/pototam_3.jpg",
        "/static/images/pototam_4.jpg",
        "/static/images/pototam_5.jpg"
      ],
      skills: [
        "Design de packaging",
        "Identité visuelle",
        "Design imprimé",
        "Maquettes web",
        "Direction artistique"
      ]
    },    
    {
      title: "Raoul",
      image: "/static/images/raoul.jpg",
      description: "Raoul est une application mobile conçue pour permettre aux utilisateurs de méditer avec leurs amis. Elle utilise la gamification pour instaurer des habitudes quotidiennes de méditation et créer des connexions entre les gens grâce à la méditation en groupe.",
      details: "En tant que fondateur, je m'occupe du développement complet de Raoul (frontend et backend), ainsi que de la création de son design et de son identité visuelle. J'ai aussi défini la stratégie globale, en intégrant de la gamification pour encourager une pratique régulière. L'application, qui sortira début 2025, propose une approche sociale unique où les utilisateurs peuvent méditer ensemble et renforcer leurs liens.",
      gallery: [
        "/static/images/raoul_1.jpg",
        "/static/images/raoul_2.jpg",
        "/static/images/raoul_3.jpg",
        "/static/images/raoul_4.jpg",
        "/static/images/raoul_5.jpg"
      ],
      skills: [
        "Développement mobile",
        "Identité visuelle",
        "Stratégie de marque",
        "Design d'application",
        "Gamification",
        "Stratégie de contenu",
      ]
    }    
];

const WorkSection = () => {
  const navigate = useNavigate();

  const handleCardClick = (title) => {
    navigate(`/${title.toLowerCase().replace(/\s+/g, '-')}`);
  };

  return (
    <Box id="work"  sx={{ pt: { xs: 6, md: 12 }, pb:{ xs: 6, md: 12 }, px: { xs: 2, md: 8 }}}>
      <Grid container columnSpacing={6} rowSpacing={2}>
        {works.map((work, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{ borderRadius: 0, mb: 0, cursor: 'pointer' }}
              elevation={0}
              onClick={() => handleCardClick(work.title)}
            >
              <CardMedia
                component="img"
                height="350"
                image={work.image}
                alt={work.title}
                sx={{ borderRadius: '4px' }}
              />
              <CardContent sx={{ p: 0, mb:0, mt:1 }}>
                <Typography variant="h5" component="div" sx={{ p: 0 }}>
                  {work.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WorkSection;