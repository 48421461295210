import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // pour obtenir l'URL actuelle
import { scroller } from 'react-scroll'; // pour scroller vers une section spécifique
import Header from '../components/Header';
import PresentationSection from '../components/PresentationSection';
import TestimonialsSlider from '../components/TestimonialsSlider';
import ExpertiseSection from '../components/ExpertiseSection';
import ProcessSection from '../components/ProcessSection';
import WorkSection from '../components/WorkSection';
import Footer from '../components/Footer';
import { Box, CircularProgress } from '@mui/material';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation(); // obtenir l'URL actuelle pour vérifier l'ancre

  useEffect(() => {
    // Simuler un délai de chargement
    const timer = setTimeout(() => {
      setLoading(false); // Terminer le chargement
    }, 1000); // Ajuste ce délai

    return () => clearTimeout(timer); // Nettoyer le timeout si le composant est démonté
  }, []);

  useEffect(() => {
    if (!loading && location.hash) {
      // Vérifier si un hash est présent dans l'URL (par exemple, #work)
      const section = location.hash.substring(1); // Retirer le #
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [loading, location]);

  if (loading) {
    // Afficher un spinner pendant le chargement
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#F9F9F9',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Header />
      <Box>
        <PresentationSection />
        <ExpertiseSection />
        <WorkSection id="work" /> {/* Ajoute un id pour la section */}
        <ProcessSection id="process" /> {/* Ajoute un id pour la section */}
        <TestimonialsSlider />
        <Footer />
      </Box>
    </>
  );
};

export default Home;
