// AvailabilityCard.js - Component for Availability Card
import React from 'react';
import { Card, Typography, Avatar, Box } from '@mui/material';
import { motion } from 'framer-motion';
import CircleIcon from '@mui/icons-material/Circle';

const AvailabilityCard = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1 }}
      style={{ position: 'fixed', bottom: 16, right: 16 }}
    >
      <Card
        sx={{
          px:1.5,
          py:1,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'transparent',
          backdropFilter: 'blur(10px)',
          boxShadow: 'none',
          borderRadius: 2,
          position: 'relative',
          cursor:'pointer'
        }}
      >
        <Avatar
          alt="Thibaut Manent"
          src="/static/images/avatar.png"
          sx={{ width: 32, height: 32, mr: 1 }}
        />
        <Box>
          <Typography variant="body2" component="p" sx={{ mb:0.3, fontWeight: 'medium', lineHeight:'1em', fontSize:'0.8em', fontFamily:'Space Mono', textTransform:'uppercase'  }}>
            Thibaut est
          </Typography>
          <Typography variant="body2" component="p" sx={{ fontWeight: 'medium', lineHeight:'1em', color: 'green', fontSize:'0.8em', fontFamily:'Space Mono', textTransform:'uppercase'  }}>
            DISPONIBLE
          </Typography>
        </Box>
        <CircleIcon
          sx={{
            color: 'green',
            fontSize: 12,
            position: 'absolute',
            top: -8,
            right: -8,
          }}
        />
      </Card>
    </motion.div>
  );
};

export default AvailabilityCard;