import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, CardMedia, Chip, CircularProgress } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ProjectPage = ({ project }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simuler un délai pour le chargement des données
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false); // Définir l'état de chargement sur false une fois que tout est prêt
    }, 1000); // tu peux ajuster ce délai

    return () => clearTimeout(timer); // Nettoyer le timeout si le composant est démonté
  }, []);

  if (loading) {
    // Afficher un spinner pendant le chargement
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#F9F9F9',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#F9F9F9' }}>
      <Header />

      {/* Section Image Principale */}
      <Box
        sx={{
          height: { xs: 'auto', md: '100vh' },
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          pt: { xs: 9, md: 5 },
        }}
      >
        <CardMedia
          component="img"
          image={project.image}
          alt={project.title}
          sx={{
            width: { xs: '100%', md: 'auto' },
            height: { xs: 'auto', md: '70%' },
            objectFit: 'cover',
            borderRadius: { xs: '0', md: '4px' },
          }}
        />
      </Box>

      <Box sx={{ px: { xs: 2, md: 8 }, py: { xs: 4, md: 8 } }}>
        {/* Section Nom du Client et Compétences */}
        <Grid container spacing={4} sx={{ mb: 8 }}>
          <Grid item xs={12} md={3}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Space Mono',
                textTransform: 'uppercase',
                mb: 1,
                fontSize: '0.9em',
              }}
            >
              Client
            </Typography>
            <Typography variant="h5" component="p" sx={{ mb: 2 }}>
              {project.title}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {project.skills.map((skill, index) => (
                <Chip key={index} label={skill} variant="outlined" />
              ))}
            </Box>
          </Grid>

          {/* Section Description et Détails */}
          <Grid item xs={12} md={9}>
            <Typography variant="h4" component="p" sx={{ mb: { xs: 4, md: 4 } }}>
              {project.description}
            </Typography>
            <Typography variant="h5" component="p">
              {project.details}
            </Typography>
          </Grid>
        </Grid>

        {/* Section Galerie */}
        <Box sx={{ mb: { xs: 6, md: 8 } }}>
          {project.gallery.map((img, index) => (
            <Box key={index} sx={{ mb: { xs: 2, md: 4 } }}>
              <CardMedia
                component="img"
                image={img}
                alt={`Gallery ${index}`}
                sx={{ width: '100%', borderRadius: '4px' }}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default ProjectPage;
