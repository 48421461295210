// Footer.js - Composant Footer
import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Footer = () => {
  return (
    <Box id="contact" sx={{ backgroundColor: '#000', color: '#FFF', pt:10, pb:{ xs: 6, md: 12 }, px:{ xs: 2, md: 8 }}}>
        <Typography variant="h2" component="h2" sx={{ mb: 0, fontSize: { xs: '2.4em', md: '4em' }}}>
        On discute ?
        </Typography>
      <Button  href="mailto:hello@thibautmanent.com" sx={{ p:0, color: '#FFF', textDecoration: 'none', fontSize: '1.5rem', fontWeight: 'medium' }}>
        <u>hello@thibautmanent.com</u>
      </Button>
      <Grid container spacing={6} justifyContent="center" sx={{ mt:0, mb:5 }}>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: 'flex-start' }}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
              <CheckCircleOutlineIcon sx={{ fontSize: 14, color: '#FFF', mr: 1 }} /> Étape 1
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Appel téléphonique
            </Typography>
            <Typography variant="body1">
              Je prends le temps de comprendre votre projet, vos besoins et vos envies pour poser les bases d'une collaboration efficace.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: 'flex-start' }}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
              <CheckCircleOutlineIcon sx={{ fontSize: 14, color: '#FFF', mr: 1 }} /> Étape 2
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Proposition détaillée
            </Typography>
            <Typography variant="body1">
              Je vous présente une solution adaptée à votre projet et votre budget, ainsi qu'une roadmap détaillée pour vos projeter.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: 'flex-start'}}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
              <CheckCircleOutlineIcon sx={{ fontSize: 14, color: '#FFF', mr: 1 }} /> Étape 3
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Accompagnement
            </Typography>
            <Typography variant="body1">
              Une fois validée, on démarre ensemble la création de votre projet et je vous accompagne à chaque étape.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
