import React from 'react';
import { Box, Typography, Grid, Button, Paper } from '@mui/material';

const ProcessSection = () => {
  return (
    <Box id="process" sx={{ backgroundColor: '#FDFAF5', pt: { xs: 0.5, md: 3 } , pb:{ xs: 10, md: 10 } , px: { xs: 2, md: 8 } }}>
      <Box sx={{ mt: 10, textAlign: 'left' }}>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={10} md={3}>
            <Typography variant="h4" sx={{ mb: { xs: 0, md: 4 }}}>
              Que vous soyez en plein lancement ou dans une phase de developpement
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={4} justifyContent="flex-end">
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 4, backgroundColor: '#fff', borderRadius: '8px' }} elevation={0}>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Minimum Viable Brand
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 4 }}>
                    Pour les indépendants et les startups qui veulent poser les bases d'une marque forte.
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
                    Atelier de stratégie et moodboards
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
                    Logo, typographies et couleurs
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
                    Support visuel (landing page, template...)
                  </Typography>
                  <Button variant="contained" color="primary" href="#contact" sx={{ mt: 4 }}>
                    Découvrir
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 4, backgroundColor: 'black', color: 'white', borderRadius: '8px' }} elevation={0}>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Accompagnement personnalisé
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 4 }}>
                    Un suivi complet pour les entreprises qui souhaite un accompagnent sur-mesure.
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
                    Stratégie et design de marque
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
                    Site web sur-mesure (e-commerce, vitrine...)
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontSize: '0.9em', fontFamily: 'Space Mono', textTransform: 'uppercase' }}>
                    Supports de communication (print, digital)
                  </Typography>
                  <Button variant="outlined" href="#contact" sx={{ mt: 4, borderColor: 'white', color: 'white' }}>
                    Prendre rdv
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProcessSection;
