// ExpertiseSection.js - Présentation des domaines d'expertise
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const ExpertiseSection = () => {
  const expertise = [
    {
      title: "Stratégie et positionnement de marque",
      details: [
        "Définition de la vision, mission et valeurs",
        "Création de la ligne éditoriale",
        "Positionnement et storytelling",
      ],
    },
    {
      title: "Identité visuelle et design graphique",
      details: [
        "Création de logo et charte graphique",
        "Design Social media content et ads",
        "Conception de print et de packaging",
      ],
    },
    {
      title: "Développement de site web et application",
      details: [
        "Création de sites vitrines",
        "Développement de sites e-commerce",
        "Applications web et mobiles",
      ],
    },
  ];

  return (
    <Box sx={{ px: { xs: 2, md: 8 }, pt: { xs: 8, md: 5 }, pb: { xs: 8, md: 5 }, backgroundColor: 'black', color: 'white' }}>
      <Grid container spacing={4}>
        {expertise.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Typography variant="h5" component="h3" sx={{ mb: 2, color: 'white' }}>
              {item.title}
            </Typography>

            <Box>
              {item.details.map((detail, idx) => (
                <Typography key={idx} variant="body1" sx={{ fontFamily: 'Space Mono', textTransform: 'uppercase', mb: 1, fontSize: '0.9em', color: 'white' }}>
                  {detail}
                </Typography>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ExpertiseSection;