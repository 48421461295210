// PresentationSection.js - Présentation avec photo à droite
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const PresentationSection = () => {
  return (
    <Grid container spacing={4} alignItems="center" sx={{ px: { xs: 2, md: 8 }, pt: { xs: 9, md: 9 }, pb: { xs: 5, md: 10 }, minHeight: '100vh', backgroundColor: '#FDFAF5' }}>
      <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
        <Typography variant="h4" component="p" sx={{ mb: 2}}>
          J'accompagne indépendants, PME et startups dans la création et le développement de leurs activités. Mon expertise en stratégie et en design de marque s'appuie sur une approche simple et directe.
        </Typography>
        <Typography variant="h5" component="p" sx={{ mb: 2, ml: { xs: 0, md: 8 }, mt: { xs: 2, md: 4 } }}>
          Depuis 2016, j'ai accompgné +20 clients, de la création de leur identité visuelle, jusqu'au développement de leur site web. Je suis également co-fondateur de <u>Papa Outang</u>, une marque de chocolats gourmands disponible chez Monoprix, et de <u>Raoul</u>, une application mobile pour méditer entre amis.
        </Typography>
        <Typography variant="body1" component="p">
          
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
        <Box component="img" src="/static/images/portrait.jpg" alt="Présentation Thibaut" sx={{ width: '100%', borderRadius: 2 }} />
      </Grid>
    </Grid>
  );
};

export default PresentationSection;