/* Inscription des routes dans App.js */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Home from './views/Home';
import AvailabilityCard from './components/AvailabilityCard';
import ProjectPage from './views/ProjectPage';
import { works } from './components/WorkSection';
import { AnimatePresence, motion } from 'framer-motion';
import './App.css';

function App() {
  const [showAvailabilityCard, setShowAvailabilityCard] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolledToBottom =
        window.innerHeight + window.scrollY >= document.documentElement.scrollHeight * 0.95;
      setShowAvailabilityCard(!isScrolledToBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {works.map((work, index) => (
            <Route
              key={index}
              path={`/${work.title.toLowerCase().replace(/\s+/g, '-')}`}
              element={<ProjectPage project={work} />}
            />
          ))}
        </Routes>
        <AnimatePresence>
          {showAvailabilityCard && (
            <motion.div
              key="availabilityCard"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <AvailabilityCard />
            </motion.div>
          )}
        </AnimatePresence>
      </Router>
    </ThemeProvider>
  );
}

export default App;