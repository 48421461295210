// Header.js - Component Header
import React from 'react';
import { AppBar, Box, Typography, Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';

const Header = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'transparent',
        backdropFilter: 'blur(10px)',
        boxShadow: 'none',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems:'center',
        px:2,
        py:2
      }}
    >
        <Typography
          variant="body"
          component={Link}
          to="/"
          sx={{
            textDecoration: 'none',
            padding: '2px 8px',
            display: 'inline-block',
            borderRadius: '3px',
            width: 'fit-content',
            fontFamily:'Space Mono',
            textTransform:'uppercase',
            fontWeight:'medium',
            fontSize:'1.4em', 
            color:'black',
            mb:0,
          }}
        >
          Thibaut Manent
        </Typography>
        <Box
          sx={{
            display: {
              xs: 'none', // Hide on small screens
              md: 'flex', // Show on medium and larger screens
            },
            gap: 2,
          }}
        >
            <Button color="inherit" href="/#work" sx={{ textTransform: 'none', color: 'black' }}>Travail</Button>
            <Button color="inherit" href="/#process" sx={{ textTransform: 'none', color: 'black' }}>Méthode</Button>
            <Button color="inherit" href="/#reviews" sx={{ textTransform: 'none', color: 'black' }}>Avis</Button>
            <IconButton color="inherit" href="/#contact">
          <MarkEmailUnreadRoundedIcon sx={{ color: 'black' }} />
        </IconButton>
        </Box>

    </AppBar>
  );
};

export default Header;